import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const AboutPage = ({ data }) => {
  const aboutPage = data.allContentfulAboutPage.nodes[0]

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <b className="font-bold">{text}</b>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} className="underline">
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3>{children}</h3>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="whitespace-pre-line">
          {children}
        </p>
      ),
    },
  }

  return (
    <Layout>
      <Seo title="About" />
      <div className="container flex items-start mx-auto pb-32">
        <div className="bg-white p-5 lg:py-20">
          <div className="container p-5 relative">
            <p data-sal="slide-left" data-sal-duration="1000" className="text-purple">
              {aboutPage.subtitle}
            </p>
            <div data-sal="fade" data-sal-duration="1000" data-sal-delay="200" className="lg:-right-64 -z-5 top-20 absolute hidden invisible lg:block lg:visible">
              <StaticImage
                src="../images/about.png"
                width={300}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Team"
              />
            </div>
            <h1 data-sal="slide-left" data-sal-duration="1000" data-sal-delay="200" className="text-black text-left py-20 block text-4xl font-semibold lg:text-6xl">
              {aboutPage.titleSection1} <span className="text-purple">{aboutPage.titleSection2}</span> {aboutPage.titleSection3}
            </h1>
            <div className="flex flex-col lg:flex-row font-serif text-lg py-10">
              <div data-sal="slide-left" data-sal-duration="1000" data-sal-delay="200" className="lg:w-1/2 max-w-lg lg:pr-20">
                {renderRichText(aboutPage.descriptionLeftColumn, options)}
              </div>
              <div data-sal="slide-left" data-sal-duration="1000" data-sal-delay="400" className="lg:w-1/2 max-w-lg lg:pr-20">
                {renderRichText(aboutPage.descriptionRightColumn, options)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutQuery {
    allContentfulAboutPage {
      nodes {
        titleSection1
        titleSection2
        titleSection3
        subtitle
        descriptionLeftColumn {
          raw
        }
        descriptionRightColumn {
          raw
        }
      }
    }
  }
`
